import { type LegacyRef, useState } from 'react'
import { useTheme } from '#app/utils/providers/themeProvider.tsx'
import imagePlaceholder from '#images/image-placeholder.jpg'
import imagePlaceholderDark from '#images/image-placeholder-dark.jpg'

const Image = ({
	containerClassName,
	className,
	imageUrl,
	imageAlt,
	dataTestId = '',
	ref = null,
}: {
	containerClassName?: string
	className?: string
	imageUrl?: string
	imageAlt?: string
	dataTestId?: string
	ref?: LegacyRef<HTMLDivElement>
}) => {
	const [imageLoaded, setImageLoaded] = useState(true)
	const [theme] = useTheme()

	const handleImageError = () => {
		setImageLoaded(false)
	}

	return (
		<div className={containerClassName} ref={ref}>
			{imageLoaded ? (
				<img
					className={className}
					src={
						imageUrl
							? imageUrl
							: theme === 'dark'
								? imagePlaceholderDark
								: imagePlaceholder
					}
					alt={imageAlt ? imageAlt : ''}
					onError={handleImageError}
					data-testid={dataTestId}
				/>
			) : (
				<img
					className={className}
					src={theme === 'dark' ? imagePlaceholderDark : imagePlaceholder}
					alt={imageAlt ? imageAlt : ''}
					data-testid={dataTestId}
				/>
			)}
		</div>
	)
}

export default Image
