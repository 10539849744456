import { Button } from '../buttons'
import {
	TypographyCaps,
	TypographyHeading,
	TypographyParagraph,
} from '../typography'
import { ArrowRightIcon } from '../icons'
import Image from '../common/image'

// Define the event content type
export type FeaturedContent = {
	bannerImage: string
	title: string
	name: string
	description: string[]
	primaryButtonText: string
	primaryButtonLink: string
	secondaryButtonText?: string
	secondaryButtonLink?: string
}

export const defaultFeaturedContent: FeaturedContent = {
	bannerImage: '/images/featured.png',
	title: 'Featured',
	name: 'TITLE NAME',
	description: [
		'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras aliquam viverra dolor non accumsan. Morbi elit lectus, cursus quis vulputate nec, mattis a dolor. Sed rutrum eleifend nisl, quis accumsan lectus convallis non. Suspendisse potenti. Curabitur id tellus a arcu consectetur hendrerit non nec eros. Vivamus congue quam a mauris.',
		'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin arcu urna, commodo a egestas non, convallis in felis. Sed faucibus tristique lectus, et lacinia metus lacinia quis. Nullam ultrices rutrum est. Integer imperdiet ac.',
	],
	primaryButtonText: 'View ',
	primaryButtonLink: 'https://www.atomiclore.io/',
	secondaryButtonText: "Ready to Secure Your Art's Legacy?",
	secondaryButtonLink: 'https://www.atomicform.com/support',
}

export default function Featured({
	content = defaultFeaturedContent,
}: {
	content?: FeaturedContent
}) {
	return (
		<div className="flex flex-col gap-[48px] gap-y-8 sm:flex-row">
			<Image
				imageUrl={content.bannerImage}
				imageAlt={content.name}
				containerClassName="sm:w-1/2 min-h-[400px] h-[400px] sm:h-auto overflow-hidden"
				className="mx-auto h-full w-auto object-cover sm:mx-0"
			/>
			<div className="flex flex-col justify-center gap-y-6 sm:w-1/2">
				<div className="flex flex-row">
					<div className="flex-flex-col">
						<TypographyCaps
							size="small"
							additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
						>
							{content.title}
						</TypographyCaps>
						<TypographyHeading
							size="large"
							isBold={true}
							additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark"
						>
							{content.name}
						</TypographyHeading>
					</div>
				</div>
				{content.description.map((paragraph, index) => (
					<TypographyParagraph
						key={index}
						size="medium"
						additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
					>
						{paragraph}
					</TypographyParagraph>
				))}
				<div className="flex flex-col items-center gap-x-6 py-0 sm:py-3 md:flex-row ">
					<a href={content.primaryButtonLink} className="w-full md:w-auto">
						<Button
							variant="primary"
							size="medium"
							rightIcon={<ArrowRightIcon />}
							additionalClasses="w-full md:w-auto"
						>
							{content.primaryButtonText}
						</Button>
					</a>
					{content.secondaryButtonText?.trim() &&
						content.secondaryButtonLink?.trim() && (
							<a
								href={content.secondaryButtonLink}
								className="whitespace-nowrap py-4 md:py-0"
							>
								<Button variant="tertiary" size="medium">
									{content.secondaryButtonText}
								</Button>
							</a>
						)}
				</div>
			</div>
		</div>
	)
}
