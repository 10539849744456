import {
	ArrowRightIcon,
	CalendarIcon,
	CollectionIcon,
	ImageIcon,
	UsersIcon,
} from '../icons'
import { TypographyHeading, TypographyParagraph } from '../typography'
import { ReactNode } from 'react'
import Image from '../common/image'
import { Tag } from '../badgesAndTags'
import InternalLink from '../common/internalLink'
import { Button } from '../buttons'
import collectionsLoreProfile from '#/images/collectionsLoreProfile.webp'
import artistsLoreProfile from '#/images/artistsLoreProfile.webp'
import eventsLoreProfile from '#/images/eventsLoreProfile.webp'
import nftsLoreProfile from '#/images/nftsLoreProfile.webp'
import { ContentTile } from '../content'

export default function LoreProfiles({
	profileSectionContent,
}: {
	profileSectionContent: any[]
}) {
	// Add validation to ensure profileSectionContent has enough items
	const hasEnoughContent =
		profileSectionContent && profileSectionContent.length >= 4

	return (
		<div className="bg-secondaryLight dark:bg-secondaryDark flex w-full flex-col gap-y-12">
			<div className="flex flex-col gap-y-4">
				<TypographyHeading
					size="large"
					isBold={true}
					additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark"
				>
					Browse Lore Profiles
				</TypographyHeading>
				<TypographyParagraph
					size="large"
					additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
				>
					Discover NFT Collections, verified artist histories and curated
					attestation archives
				</TypographyParagraph>
			</div>
			<div className="grid w-full grid-cols-1 items-stretch gap-y-4 text-textSecondaryLight dark:text-textSecondaryDark sm:grid-cols-4 sm:gap-x-4">
				<BrowseItem
					description={
						'Immutable collection records signed and verified by artists, establishing permanent onchain provenance.'
					}
					tagText={''}
					imageIcon={<CollectionIcon />}
					imageText={'Onchain Collections'}
					image={collectionsLoreProfile}
					contentTile={
						hasEnoughContent && profileSectionContent[0] ? (
							<a href={profileSectionContent[0].link} target="_blank">
								<ContentTile
									variant="collection"
									title={profileSectionContent[0].title}
									description={profileSectionContent[0].description}
									image={profileSectionContent[0].image}
									isVerified={true}
									showCaret={true}
									icon={null}
								/>
							</a>
						) : null
					}
					linkText={'Browse all Collections'}
					link={''}
				/>
				<BrowseItem
					description={
						'Artist portfolios with verified collections and attestations across their work.'
					}
					tagText={''}
					imageIcon={<UsersIcon />}
					imageText={'Artist Profiles'}
					image={artistsLoreProfile}
					contentTile={
						hasEnoughContent && profileSectionContent[1] ? (
							<a href={profileSectionContent[1].link} target="_blank">
								<ContentTile
									variant="collection"
									title={profileSectionContent[1].title}
									description={profileSectionContent[1].description}
									image={profileSectionContent[1].image}
									isVerified={true}
									showCaret={true}
									icon={null}
								/>
							</a>
						) : null
					}
					linkText={'Browse all Artists'}
					link={''}
				/>
				<BrowseItem
					description={
						'Current and past exhibitions, IRL gallery shows, and digital art events worldwide.'
					}
					tagText={''}
					imageIcon={<CalendarIcon />}
					imageText={'Events and Exhibitions'}
					image={eventsLoreProfile}
					contentTile={
						hasEnoughContent && profileSectionContent[2] ? (
							<a href={profileSectionContent[2].link} target="_blank">
								<ContentTile
									variant="collection"
									title={profileSectionContent[2].title}
									description={profileSectionContent[2].description}
									image={profileSectionContent[2].image}
									isVerified={true}
									showCaret={true}
									icon={null}
								/>
							</a>
						) : null
					}
					linkText={'Browse all Events'}
					link={''}
				/>
				<BrowseItem
					description={
						"A complete history of an NFT's lore and provenance records."
					}
					tagText={''}
					imageIcon={<ImageIcon />}
					imageText={'NFT Profiles'}
					image={nftsLoreProfile}
					contentTile={
						hasEnoughContent && profileSectionContent[3] ? (
							<a href={profileSectionContent[3].link} target="_blank">
								<ContentTile
									variant="collection"
									title={profileSectionContent[3].title}
									description={profileSectionContent[3].description}
									image={profileSectionContent[3].image}
									isVerified={true}
									showCaret={true}
									icon={null}
								/>
							</a>
						) : null
					}
					linkText={'Browse all NFTs'}
					link={''}
				/>
			</div>
		</div>
	)
}

const BrowseItem = ({
	description,
	tagText,
	imageIcon,
	imageText,
	image,
	contentTile,
	linkText,
	link,
}: {
	description: string
	tagText: string
	imageIcon: ReactNode
	imageText: string
	image: string
	contentTile: ReactNode
	linkText: string
	link: string
}) => {
	return (
		<div className="flex min-h-full flex-col rounded-lg border border-uiPrimaryLight bg-uiTertiaryLight dark:border-uiTertiaryDark dark:bg-grey950">
			<div className="relative">
				<Image
					containerClassName="w-full rounded-t-lg"
					className="w-full rounded-t-lg object-cover"
					imageUrl={image}
					imageAlt={imageText}
				/>

				<div className="absolute inset-0 rounded-t-lg bg-black bg-opacity-50" />

				<div className="absolute right-4 top-4 flex flex-col">
					{tagText ? (
						<Tag variant="fill" showIcon={false}>
							{tagText}
						</Tag>
					) : null}
				</div>

				<div className="absolute bottom-4 left-4 flex flex-row gap-x-2 text-textSecondaryDark">
					{imageIcon ? imageIcon : null}
					{imageText ? (
						<TypographyParagraph
							size="xLarge"
							isBold={true}
							additionalClasses="text-textPrimaryDark dark:text-textPrimaryDark"
						>
							{imageText}
						</TypographyParagraph>
					) : null}
				</div>
			</div>
			<div className="flex flex-grow flex-col gap-y-2 p-4 pb-6">
				<TypographyParagraph
					size="medium"
					additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark flex-grow"
				>
					{description}
				</TypographyParagraph>
				<div className="flex flex-grow flex-col justify-end">
					{contentTile ? contentTile : null}
				</div>
			</div>
			{linkText && link ? (
				<div className="flex flex-col gap-y-2 p-4">
					<InternalLink to={link}>
						<Button variant="tertiary" rightIcon={<ArrowRightIcon />}>
							{linkText}
						</Button>
					</InternalLink>
				</div>
			) : null}
		</div>
	)
}
