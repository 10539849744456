import { AttestationType } from '#app/server/db.server.ts'
import {
	getReferenceURL,
	mapIcon,
	formatMetadataType,
	getMetadataProfileURL,
	truncateStringInMiddle,
	mapSigners,
} from '#app/utils/misc.tsx'
import { getElapsedTimeString } from '#app/utils/timeUtils.ts'
import { Badge } from '../badgesAndTags'
import { Button } from '../buttons'
import { WebsiteIcon, ArrowUpRight, UsersIcon, ClockIcon } from '../icons'
import {
	TypographyLabel,
	TypographyHeading,
	TypographyParagraph,
} from '../typography'
import Image from './image'
import InternalLink from './internalLink'

export default function LoreCard({
	attestation,
	showShadow = false,
}: {
	attestation: AttestationType
	showShadow?: boolean
}) {
	return (
		<InternalLink to={getReferenceURL(attestation)}>
			<div
				className={`group flex flex-col gap-y-3 rounded-md border border-transparent bg-uiTertiaryLight p-4 transition-all duration-300 ease-out hover:border-uiPrimaryLight dark:bg-grey950 dark:hover:border-transparent ${showShadow ? 'drop-shadow-menuDark dark:drop-shadow-none' : ''}`}
			>
				<div className="flex flex-row items-center justify-between">
					<Badge icon={mapIcon(attestation.template.slug, 'small')}>
						{formatMetadataType(attestation.template.slug)}
					</Badge>
					<InternalLink
						to={getMetadataProfileURL(attestation)}
						prefetch="intent"
						additionalClasses="opacity-0 group-hover:opacity-100 transition-all duration-300 ease-out"
					>
						<Button
							variant="tertiary"
							size="xSmall"
							leftIcon={<WebsiteIcon size="small" />}
							rightIcon={<ArrowUpRight size="small" />}
							additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark py-0 h-auto"
						>
							Provenance
						</Button>
					</InternalLink>
				</div>
				<div className="relative aspect-[3/2] w-full">
					<Image
						containerClassName="w-full h-full aspect-[3/2]"
						className="h-full w-full object-cover"
						imageUrl={attestation.nftImage}
						imageAlt={attestation.nftName}
					/>

					<div className="absolute inset-0 bg-gradient-to-t from-textPrimaryLight to-transparent opacity-0 transition-opacity duration-300 ease-out group-hover:opacity-100"></div>

					<div className="absolute bottom-4 left-4 flex flex-col gap-y-2 opacity-0 transition-opacity duration-300 ease-out group-hover:opacity-100">
						{attestation.references?.entry?.chain ? (
							<Badge
								icon={null}
								variant="fill"
								additionalClasses="w-fit !text-textPrimaryLight !border-textInactiveLight !bg-uiPrimaryLight"
							>
								{attestation.references?.entry?.chain?.id}
							</Badge>
						) : null}

						<TypographyLabel
							size="medium"
							additionalClasses="text-textPrimaryDark dark:text-textPrimaryDark"
						>
							{attestation.nftName}
						</TypographyLabel>

						{attestation.references?.entry?.nft?.contractAddress ? (
							<TypographyLabel
								size="small"
								additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
							>
								{truncateStringInMiddle(
									attestation.references?.entry?.nft?.contractAddress,
								)}
							</TypographyLabel>
						) : null}
					</div>
				</div>
				<TypographyHeading
					size="medium"
					isBold={true}
					additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark line-clamp-2"
				>
					{attestation.properties.title
						? attestation.properties.title
						: attestation.nftName}
				</TypographyHeading>
				{attestation.properties.description ? (
					<TypographyParagraph
						size="medium"
						additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark line-clamp-4"
					>
						{attestation.properties.description}
					</TypographyParagraph>
				) : (
					''
				)}
				<div className="flex flex-row items-center justify-between">
					<div className="flex flex-row items-center gap-x-2 text-textSecondaryLight dark:text-textSecondaryDark">
						<UsersIcon size="small" />
						<TypographyParagraph
							size="medium"
							additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
						>
							{`${mapSigners(attestation).length} ${mapSigners(attestation).length === 1 ? 'Signature' : 'Signatures'}`}
						</TypographyParagraph>
					</div>
					<div className="flex flex-row items-center gap-x-2 text-textSecondaryLight dark:text-textSecondaryDark">
						<ClockIcon />
						<TypographyParagraph
							size="medium"
							additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
						>
							{getElapsedTimeString(
								attestation.comparisonDate || attestation.createdAt,
							)}
						</TypographyParagraph>
					</div>
				</div>
			</div>
		</InternalLink>
	)
}
