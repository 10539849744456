import Hero from './hero.tsx'
import RecentlySigned from './recentlySigned.tsx'
import DocumentArtJourney from './documentArtJourney.tsx'
import LoreProfiles from './loreProfiles.tsx'
import Featured from './featured.tsx'

export default function Home({
	helpUrl,
	recordSectionAttestations,
	profileSectionContent,
	featuredContent,
}: {
	helpUrl: string
	recordSectionAttestations: string[]
	profileSectionContent: any[]
	featuredContent: any
}) {
	return (
		<div className="flex flex-col gap-y-16 md:gap-y-20 lg:gap-y-32 xl:gap-y-36 ">
			<div className="bg-white dark:bg-black">
				<div className="mx-auto flex flex-col items-center">
					<Hero helpUrl={helpUrl} />
				</div>
			</div>
      <div className="mx-auto w-full max-w-[1440px] bg-white px-6 dark:bg-black sm:px-6 md:mx-16 md:px-12 lg:mx-20 lg:px-24 xl:mx-auto">
				<LoreProfiles profileSectionContent={profileSectionContent} />
			</div>
			<div className="mx-auto w-full max-w-[1440px] bg-white px-6 dark:bg-black sm:px-6 md:mx-16 md:px-12 lg:mx-20 lg:px-24 xl:mx-auto">
				<RecentlySigned />
			</div>
			{featuredContent && (
				<div className="mx-auto w-full max-w-[1440px] bg-white px-6 dark:bg-black sm:px-6 md:mx-16 md:px-12 lg:mx-20 lg:px-24 xl:mx-auto">
					<Featured content={featuredContent} />
				</div>
			)}
			<div className="mx-auto w-full max-w-[1440px] bg-white px-6 dark:bg-black sm:px-6 md:mx-16 md:px-12 lg:mx-20 lg:px-24 xl:mx-auto">
				<DocumentArtJourney
					recordSectionAttestations={recordSectionAttestations}
				/>
			</div>
		</div>
	)
}
