import { TypographyHeading } from '../typography'
import Search from './search'

export default function Hero({ helpUrl }: { helpUrl: string }) {
	return (
		<>
			<div className="w-full sm:px-6 md:px-12">
				<div className="sm:px-6 md:px-12">
					<div className="mt-16 flex h-full w-full flex-col items-center px-8 sm:px-16 md:px-20 lg:px-32">
						<TypographyHeading
							size="large"
							isBold={true}
							additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark text-center !text-7xl md:!text-9xl"
						>
							Permanent. Verified.
						</TypographyHeading>
						<TypographyHeading
							size="large"
							additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark text-center !text-7xl sm:!text-9xl italic"
						>
							Onchain.
						</TypographyHeading>
						<TypographyHeading
							size="small"
							additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark text-center mt-8"
						>
							Create a decentralized registry of your artwork’s exhibitions,
							achievements, and lore.
						</TypographyHeading>
					</div>
				</div>
			</div>
			<div className="mt-32 flex w-full max-w-[1440px] flex-row justify-center sm:px-6 sm:px-6 md:px-12 lg:px-24">
				<Search helpUrl={helpUrl} />
			</div>
		</>
	)
}
