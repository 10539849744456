import { AttestationType } from '#app/server/db.server.ts'
import { Button } from '../buttons'
import InternalLink from '../common/internalLink'
import LoreCard from '../common/loreCard'
import LoreCardSkeleton from '../common/loreCardSkeleton'
import { ArrowRightIcon } from '../icons'
import { TypographyHeading } from '../typography'
import { useEffect, useState } from 'react'

export default function RecentlySigned() {
	const [loading, setLoading] = useState(false)
	const [attestations, setAttestations] = useState<AttestationType[]>([])

	useEffect(() => {
		loadMetadata()
	}, [])

	const loadMetadata = async () => {
		if (loading) return
		setLoading(true)
		try {
			const options = {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			}
			const response = await fetch(`/recentAttestationsAPI?pageSize=9`, options)
			const metadataIndex = (await response.json()) as {
				mintedMetadata: AttestationType[]
				totalMetadata: number
			}
			setAttestations(metadataIndex.mintedMetadata)
		} catch (e) {
			console.log(e)
		}
		setLoading(false)
	}

	return (
		<div className="bg-secondaryLight dark:bg-secondaryDark flex w-full flex-col gap-y-12">
			<div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
				<TypographyHeading
					size="large"
					isBold={true}
					additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark"
				>
					Recently Archived
				</TypographyHeading>
				<InternalLink to="/feed" prefetch="intent">
					<Button variant="tertiary" rightIcon={<ArrowRightIcon />}>
						View All Lore
					</Button>
				</InternalLink>
			</div>
			<div className="columns-1 gap-4 sm:columns-2 md:columns-3">
				{attestations.length
					? attestations.map((attestation, index) => (
							<div key={index} className="mb-4 break-inside-avoid">
								<LoreCard attestation={attestation} />
							</div>
						))
					: Array.from({ length: 9 }).map((_, index) => (
							<div key={index} className="mb-4 w-full break-inside-avoid">
								<LoreCardSkeleton key={index} />
							</div>
						))}
			</div>
		</div>
	)
}
