import { TypographyCaps } from '../typography'
import { PlusIcon } from '../icons'
import clsx from 'clsx'
import { type ReactNode } from 'react'

const Badge = ({
	icon = <PlusIcon />,
	children,
	variant = 'default',
	additionalClasses = '',
	...props
}: {
	icon?: ReactNode
	children: ReactNode
	variant?: 'default' | 'outline' | 'fill'
	additionalClasses?: string
}) => {
	return (
		<div
			className={clsx(
				'flex items-center',
				'rounded-[4px] text-textSecondaryLight dark:text-textSecondaryDark',
				variant === 'default' && 'bg-transparent',
				variant === 'outline' &&
					'border border-textInactiveLight px-2 py-1 dark:border-textInactiveDark',
				variant === 'fill' &&
					'bg-uiPrimaryLight px-2 py-1 dark:bg-uiPrimaryDark',
				additionalClasses,
			)}
			{...props}
		>
			{icon && (
				<div className="mr-1 flex h-6 w-6 items-center justify-center">
					{icon}
				</div>
			)}
			<TypographyCaps size="small" additionalClasses={clsx('pt-0.5')}>
				{children}
			</TypographyCaps>
		</div>
	)
}

export default Badge
