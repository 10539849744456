const ClockIcon = ({
	size = 'large',
	...props
}: {
	size?: 'small' | 'medium' | 'large'
}) => (
	<svg
		{...props}
		width="17"
		height="17"
		viewBox="0 0 17 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.66797 5.16667V8.5H12.0013M8.66797 14.5C5.35426 14.5 2.66797 11.8137 2.66797 8.5C2.66797 5.18629 5.35426 2.5 8.66797 2.5C11.9817 2.5 14.668 5.18629 14.668 8.5C14.668 11.8137 11.9817 14.5 8.66797 14.5Z"
			stroke="currentColor"
			strokeWidth="1.3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default ClockIcon
