import { Form, useActionData, useNavigation, useSubmit } from '@remix-run/react'
import Button from '../buttons/button'
import { InputField, RadioInput, SearchInput } from '../inputs'
import {
	TypographyCaps,
	TypographyLabel,
	TypographyParagraph,
} from '../typography'
import { Tooltip } from '../modalsAndOverlays'
import Callout from '../callout/callout'
import {
	BlurIcon,
	EtherscanIcon,
	ExchangeArtIcon,
	ZoraIcon,
	OpenSeaIcon,
	SolanaFMIcon,
	TZKTIcon,
	ObjktIcon,
	HighlightIcon,
} from '../icons'
import ShapeLogo from '#app/components/common/logos/ShapeLogo.tsx'
import { useEffect, useState } from 'react'
import Dropdown, { selectOption } from '../common/dropdown.tsx'
import { Transition } from '@headlessui/react'
import useAnalytics from '#app/utils/analytics/useAnalytics'
import { chainOptions } from '#app/utils/misc'

export default function Search({ helpUrl }: { helpUrl: string }) {
	const actionDataErrors = useActionData<typeof action>()
	const submit = useSubmit()
	const navigation = useNavigation()
	const { trackEvent } = useAnalytics()

	const [errors, setErrors] = useState(actionDataErrors || {})
	const [searchMethod, setSearchMethod] = useState('Marketplace')
	const [contractAddress, setContractAddress] = useState('')
	const [tokenId, setTokenId] = useState('')
	const [link, setLink] = useState('')
	const [chain, setChain] = useState<selectOption>(chainOptions[0])

	useEffect(() => {
		if (actionDataErrors) {
			setErrors(actionDataErrors)
		}
	}, [actionDataErrors])

	function handleSearch(event: {
		currentTarget:
			| URLSearchParams
			| FormData
			| HTMLFormElement
			| HTMLButtonElement
			| HTMLInputElement
			| { [name: string]: string }
			| null
	}) {
		event.preventDefault()
		setErrors({})
		if (event.currentTarget) {
			const formData = new FormData(event.currentTarget)
			if (formData) {
				const formValues: { [key: string]: string } = {}
				for (const [key, value] of formData.entries()) {
					if (key !== 'AnonymousId') {
						formValues[key] = value as string
					}
				}
				if (searchMethod) {
					formValues['Search Method'] = searchMethod
				} else {
					if (formValues['chain']) {
						formValues['Search Method'] = 'Token Information'
					} else {
						formValues['Search Method'] = 'Marketplace'
					}
				}

				trackEvent('Client_NFT_Search', formValues)
			}
		}
		submit(event.currentTarget, { replace: true })
	}

	return (
		<div
			className={`transition-height duration-300 ease-in-out ${errors ? 'h-fit' : searchMethod === 'Marketplace' ? 'h-72 sm:!h-40' : 'h-[420px] sm:!h-60'} z-10 flex w-full flex-col gap-x-16 rounded-md border border-uiPrimaryLight bg-uiTertiaryLight p-4 drop-shadow-lg dark:border-uiSecondaryDark dark:bg-uiTertiaryDark sm:-mt-16 md:flex-row`}
		>
			<div className="flex w-full flex-col">
				<div className="mb-2 flex flex-row justify-between">
					<div className="items-left flex flex-col sm:flex-row sm:items-center">
						<TypographyParagraph
							size="medium"
							additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark"
						>
							Find your NFT using either of these methods:
						</TypographyParagraph>
						<div className="flex flex-row items-center sm:ml-2">
							<RadioInput
								key="Marketplace"
								name="Marketplace"
								size="large"
								onClick={() => setSearchMethod('Marketplace')}
								current={searchMethod === 'Marketplace'}
							/>
							<RadioInput
								key="Token Information"
								name="Token Information"
								size="large"
								onClick={() => setSearchMethod('Token Information')}
								current={searchMethod === 'Token Information'}
							/>
						</div>
					</div>
					{helpUrl && (
						<div className="hidden flex-row items-center sm:flex">
							<a href={helpUrl} target="_blank">
								<Button
									variant="tertiary"
									size="small"
									additionalClasses="ml-2 text-textPrimaryLight dark:text-textPrimaryDark"
								>
									Need help?
								</Button>
							</a>
						</div>
					)}
				</div>
				<Form method="post" className="w-full" onSubmit={handleSearch}>
					<div className="flex flex-col justify-between">
						<Transition
							show={searchMethod === 'Marketplace'}
							enter="transition-all duration-500 ease-in"
							enterFrom="opacity-0 h-0"
							enterTo="opacity-100 h-full"
						>
							<div className="flex flex-col">
								<div className="items-left flex flex-col gap-x-4 gap-y-2 sm:flex-row sm:items-center">
									<SearchInput
										name="Search by URL"
										placeholder="Enter Marketplace URL"
										value={link}
										setValue={newVal => {
											setLink(newVal)
										}}
										containerWidth="w-full"
										//@ts-ignore
										error={errors?.link}
									/>
									<Button
										additionalClasses="min-h-full whitespace-nowrap"
										variant="primary"
										size="large"
										disabled={navigation.state !== 'idle' || !link.length}
									>
										Find your NFT
									</Button>
								</div>
								{errors && errors.link && (
									<Callout
										variant="error"
										content={errors.link}
										additionalClasses="mt-2 bg:uiTertiaryLight dark:bg-uiTertiaryDark"
									/>
								)}
								<div className="items-left mt-2.5 flex flex-col gap-x-2 gap-y-4 sm:flex-row sm:items-center">
									<TypographyLabel
										size="small"
										additionalClasses="text-textSecondaryLight dark:text-textInactiveDark"
									>
										We support links from:
									</TypographyLabel>
									<div className="flex flex-row gap-x-4 text-textPrimaryLight dark:text-textPrimaryDark">
										<Tooltip
											title="Etherscan"
											tip="Bottom"
											content={<span>EVM</span>}
										>
											<EtherscanIcon aria-label="Etherscan" />
										</Tooltip>
										<Tooltip
											title="OpenSea"
											tip="Bottom"
											content={<span>Multichain</span>}
										>
											<OpenSeaIcon aria-label="OpenSea" />
										</Tooltip>
										<Tooltip
											title="Blur"
											tip="Bottom"
											content={<span>EVM</span>}
										>
											<BlurIcon aria-label="Blur" />
										</Tooltip>
										<Tooltip
											title="SolanaFM"
											tip="Bottom"
											content={<span>Solana</span>}
										>
											<SolanaFMIcon aria-label="SolanaFM" />
										</Tooltip>
										<Tooltip
											title="ExchangeArt"
											tip="Bottom"
											content={<span>Multichain</span>}
										>
											<ExchangeArtIcon aria-label="ExchangeArt" />
										</Tooltip>
										<Tooltip
											title="Zora"
											tip="Bottom"
											content={<span>Multichain</span>}
										>
											<ZoraIcon aria-label="Zora" />
										</Tooltip>
										<Tooltip
											title="TZKT"
											tip="Bottom"
											content={<span>Tezos</span>}
										>
											<TZKTIcon aria-label="TZKT" />
										</Tooltip>
										<Tooltip
											title="Objkt"
											tip="Bottom"
											content={<span>Tezos</span>}
										>
											<ObjktIcon aria-label="Objkt" />
										</Tooltip>
										<Tooltip
											title="Shape"
											tip="Bottom"
											content={<span>Shape</span>}
										>
											<ShapeLogo aria-label="Shape" />
										</Tooltip>
										<Tooltip
											title="Highlight"
											tip="Bottom"
											content={<span>Highlight</span>}
										>
											<HighlightIcon aria-label="Highlight" />
										</Tooltip>
									</div>
								</div>
								{helpUrl && (
									<div className="mt-2 block flex flex-row items-center sm:hidden">
										<a href={helpUrl} target="_blank">
											<Button
												variant="tertiary"
												size="small"
												additionalClasses="ml-2 text-textPrimaryLight dark:text-textPrimaryDark"
											>
												Need help?
											</Button>
										</a>
									</div>
								)}
							</div>
						</Transition>
						<Transition
							show={searchMethod === 'Token Information'}
							enter="transition-all duration-500 ease-in"
							enterFrom="opacity-0 h-0"
							enterTo="opacity-100 h-full"
						>
							<div className="flex flex-col">
								<div className="flex flex-col gap-x-2 sm:flex-row">
									<div className="flex w-full flex-col">
										<div className="flex flex-col">
											<TypographyCaps
												size="small"
												additionalClasses="text-textSecondaryDark dark:text-textSecondaryLight"
											>
												Chain network
											</TypographyCaps>
											<Dropdown
												options={chainOptions}
												title={''}
												placeholder="Select a chain"
												selected={chain}
												onSelectionChange={selected => {
													setChain(selected)
												}}
												variant="input"
												containerClasses="w-full"
											/>
											<input
												type="hidden"
												name="chain"
												id="chain"
												value={chain ? chain.slug : ''}
											/>
										</div>
										<div className="mt-2 flex flex-col items-center gap-x-4 gap-y-4 sm:flex-row">
											<div
												className={`flex w-full flex-col ${chain && chain.slug === 'solana' ? 'w-full' : 'sm:w-1/2'}`}
											>
												<InputField
													name={
														chain && chain.slug === 'solana'
															? 'Token Address'
															: 'Contract Address'
													}
													type="text"
													placeholder={
														chain && chain.slug === 'solana'
															? 'Enter SOL Token Address'
															: `Enter ${chain ? chain.symbol : ''} Contract Address`
													}
													value={contractAddress}
													onChange={e => {
														setContractAddress(e.target.value)
													}}
													containerWidth="w-full"
													//@ts-ignore
													error={errors?.contractAddress}
												/>
											</div>
											{(!chain || chain.slug != 'solana') && (
												<div className="flex w-full flex-col sm:w-1/2">
													<InputField
														name="Token ID"
														type="text"
														placeholder="Enter Token ID"
														value={tokenId}
														onChange={e => {
															setTokenId(e.target.value)
														}}
														containerWidth="w-full"
														//@ts-ignore
														error={errors?.tokenId}
													/>
												</div>
											)}
										</div>
									</div>
									<div className="flex flex-col justify-end">
										<Button
											additionalClasses="mt-2 py-6 whitespace-nowrap"
											variant="primary"
											disabled={
												navigation.state !== 'idle' ||
												!chain ||
												(chain.slug != 'solana' && !tokenId.length) ||
												!contractAddress.length
											}
										>
											Find your NFT
										</Button>
									</div>
								</div>
								{errors && (errors.contractAddress || errors.tokenId) && (
									<Callout
										variant="error"
										content="We couldn’t find any NFT with the values you entered. Please make sure the contract address is valid and it belongs to the selected chain."
										additionalClasses="mt-4 bg:uiTertiaryLight dark:bg-uiTertiaryDark"
									/>
								)}
							</div>
						</Transition>
					</div>
				</Form>
			</div>
		</div>
	)
}
