import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function LoreCardSkeleton({ showShadow = false }) {
	return (
		<div
			className={`group flex w-full flex-col gap-y-3 rounded-md bg-uiTertiaryLight p-4 dark:bg-grey950 ${showShadow ? 'drop-shadow-menuDark dark:drop-shadow-none' : ''}`}
		>
			<div className="flex flex-row items-center justify-between">
				<Skeleton
					circle
					width={20}
					height={20}
					baseColor="var(--skeleton-base)"
					highlightColor="var(--skeleton-highlight)"
				/>
				<Skeleton
					width={60}
					height={16}
					baseColor="var(--skeleton-base)"
					highlightColor="var(--skeleton-highlight)"
				/>
			</div>
			<div className="relative">
				<Skeleton
					height={180}
					className="w-full"
					baseColor="var(--skeleton-base)"
					highlightColor="var(--skeleton-highlight)"
				/>
			</div>
			<Skeleton
				height={20}
				width="80%"
				baseColor="var(--skeleton-base)"
				highlightColor="var(--skeleton-highlight)"
			/>
			<Skeleton
				height={16}
				width="90%"
				count={2}
				baseColor="var(--skeleton-base)"
				highlightColor="var(--skeleton-highlight)"
			/>
			<div className="flex flex-row items-center justify-between">
				<div className="flex flex-row items-center gap-x-2 text-textSecondaryLight dark:text-textSecondaryDark">
					<Skeleton
						circle
						width={20}
						height={20}
						baseColor="var(--skeleton-base)"
						highlightColor="var(--skeleton-highlight)"
					/>
					<Skeleton
						width={60}
						height={16}
						baseColor="var(--skeleton-base)"
						highlightColor="var(--skeleton-highlight)"
					/>
				</div>
				<div className="flex flex-row items-center gap-x-2 text-textSecondaryLight dark:text-textSecondaryDark">
					<Skeleton
						circle
						width={20}
						height={20}
						baseColor="var(--skeleton-base)"
						highlightColor="var(--skeleton-highlight)"
					/>
					<Skeleton
						width={60}
						height={16}
						baseColor="var(--skeleton-base)"
						highlightColor="var(--skeleton-highlight)"
					/>
				</div>
			</div>
		</div>
	)
}
