import { AttestationType } from '#app/server/db.server.ts'
import LoreCard from '../common/loreCard'
import { CompletedIcon } from '../icons'
import { PillTab } from '../tabs'
import { TypographyHeading, TypographyParagraph } from '../typography'
import { useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import LoreCardSkeleton from '../common/loreCardSkeleton'

export default function DocumentArtJourney({
	recordSectionAttestations,
}: {
	recordSectionAttestations: string[]
}) {
	const [loading, setLoading] = useState(false)
	const [attestations, setAttestations] = useState<AttestationType[]>([])
	const [selectedSection, setSelectedSection] = useState('Exhibition Records')

	useEffect(() => {
		loadMetadata()
	}, [])

	const loadMetadata = async () => {
		if (loading) return
		setLoading(true)
		try {
			const options = {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			}

			const responses = await Promise.all(
				recordSectionAttestations.map(async attestation => {
					const response = await fetch(
						`/attestationAPI/${attestation}`,
						options,
					)
					return response.json()
				}),
			)

			const allMintedMetadata = responses
				.map(metadataIndex => metadataIndex.mintedMetadata)
				.flat()

			setAttestations(allMintedMetadata)
		} catch (e) {
			console.log(e)
		}
		setLoading(false)
	}

	return (
		<div className="flex w-full flex-col items-start gap-y-12">
			<div className="flex w-full flex-col items-start gap-y-4">
				<TypographyHeading
					size="large"
					isBold={true}
					additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark"
				>
					Record Your Art’s Evolution
				</TypographyHeading>
				<TypographyParagraph
					size="large"
					additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
				>
					Create a comprehensive, verifiable history of your artwork's impact
					and evolution
				</TypographyParagraph>

				<div className="scrollbar-hidden mt-6 w-full min-w-0 overflow-x-auto">
					<div className="inline-flex gap-x-2">
						{[
							'Exhibition Records',
							'Press & Media',
							'Social Proof',
							'Collection',
						].map((title, index) => (
							<PillTab
								key={index}
								title={title}
								selected={selectedSection === title}
								onClick={() => setSelectedSection(title)}
							/>
						))}
					</div>
				</div>
			</div>

			<div
				className={`flex h-auto w-full flex-col transition-height duration-300 ease-in-out`}
			>
				<Section
					selected={selectedSection === 'Exhibition Records'}
					attestation={attestations[0]}
					title="Exhibition Records"
					subtext="Create a comprehensive, verifiable history of your artwork's impact and evolution"
					items={[
						{
							title: 'Multi-Party Verification',
							subtext:
								'Get signatures from curators, galleries, and institutions',
						},
						{
							title: 'Exhibition Details',
							subtext: 'Record dates, locations, and visitor metrics',
						},
						{
							title: 'Venue Authentication',
							subtext: 'Verified gallery and museum profiles',
						},
					]}
				/>
				<Section
					selected={selectedSection === 'Press & Media'}
					attestation={attestations[1]}
					title="Press & Media Records"
					subtext="Create permanent records of press coverage, reviews, and critical discourse"
					items={[
						{
							title: 'Publication Verification',
							subtext: 'Authenticated media sources and authors',
						},
						{
							title: 'Content Archival',
							subtext: 'Permanent storage on IPFS and Arweave',
						},
						{
							title: 'Media Integration',
							subtext: 'Support for articles, news and websites',
						},
					]}
				/>
				<Section
					selected={selectedSection === 'Social Proof'}
					attestation={attestations[2]}
					title="Social Proof"
					subtext="Preserve significant social media moments and community engagement"
					items={[
						{
							title: 'Cross-Platform Integration',
							subtext: 'Link Twitter, Instagram and Farcaster posts',
						},
						{
							title: 'Thread Preservation',
							subtext: 'Complete conversation context',
						},
						{
							title: 'Engagement Metrics',
							subtext: 'Preserve reach and impact data',
						},
					]}
				/>
				<Section
					selected={selectedSection === 'Collection'}
					attestation={attestations[3]}
					title="Onchain Collection"
					subtext="Immutable records for established NFT collections"
					items={[
						{
							title: 'Permanent, immutable collection documentation',
							subtext: 'Metadata saved to IPFS and Arweave',
						},
						{
							title: 'Link existing marketplace collections',
							subtext: 'All your collections, in one place',
						},
						{
							title: 'Verified artist ownership records',
							subtext: 'Catalog cross-chain and multi-contract collections',
						},
					]}
				/>
			</div>
		</div>
	)
}

const Section = ({
	selected,
	attestation,
	title,
	subtext,
	items,
}: {
	selected: boolean
	attestation: AttestationType
	title: string
	subtext: string
	items: { title: string; subtext: string }[]
}) => {
	return (
		<Transition
			show={selected}
			enter="transition-all duration-300 ease-in"
			enterFrom="opacity-0 h-0"
			enterTo="opacity-100 h-full"
		>
			<div className="flex flex-col gap-x-16 gap-y-16 sm:flex-row">
				<div className="w-full sm:w-5/12">
					{attestation ? (
						<LoreCard attestation={attestation} showShadow={true} />
					) : (
						<LoreCardSkeleton />
					)}
				</div>
				<div className="flex w-full flex-col gap-y-6 pt-4 sm:w-7/12">
					<TypographyHeading
						size="large"
						isBold={true}
						additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark"
					>
						{title}
					</TypographyHeading>
					<TypographyParagraph
						size="medium"
						additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
					>
						{subtext}
					</TypographyParagraph>
					<div className="flex flex-col gap-y-4">
						{items.map((item, index) => (
							<Item key={index} title={item.title} subtext={item.subtext} />
						))}
					</div>
				</div>
			</div>
		</Transition>
	)
}

const Item = ({ title, subtext }: { title: string; subtext: string }) => {
	return (
		<div className="flex flex-row gap-6 rounded-md border border-uiPrimaryLight p-4 text-textPrimaryLight dark:border-uiSecondaryDark dark:text-textPrimaryDark">
			<CompletedIcon />
			<div className="flex flex-col gap-y-2">
				<TypographyParagraph
					size="medium"
					additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark"
				>
					{title}
				</TypographyParagraph>
				<TypographyParagraph
					size="medium"
					additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
				>
					{subtext}
				</TypographyParagraph>
			</div>
		</div>
	)
}
